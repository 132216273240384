import Web from '../../svg/Web';
import Folder from '../../svg/Folder';
import KVKK from '../../svg/KVKK';
import ConsultItem from './ConsultItem';

export default function Danismanlik({ dataConsult, locale }) {


    return (
        <div className="container">
            <div className="row">
                <div className="col my-1 mb-lg-3">
                    <h2 className="text-center text-8 line-height-3 font-weight-bold mb-5 mb-lg-10 text-dark">
                        {dataConsult.title} </h2>
                    <div className="row text-md-left pb-3 align-items-start justify-content-center">
                        <ConsultItem
                            title={dataConsult.isoTitle}
                            link={dataConsult.isoLink}
                            definition={dataConsult.p}>
                            <Folder className="iso-27001 mb-lg-3 mb-2" />
                        </ConsultItem>
                        {locale === 'tr' && <ConsultItem
                            title={dataConsult.gdprTitle}
                            link={dataConsult.gdprLink ? dataConsult.gdprLink : ''}
                            definition={dataConsult.p1}>
                            <KVKK className="iso-27001 mb-lg-3 mb-2"
                                title={dataConsult.gdprTitle} />
                        </ConsultItem>}
                        <ConsultItem
                            title={dataConsult.domainTitle}
                            link={dataConsult.domainLink}
                            definition={dataConsult.p2}>
                            <Web className="iso-27001 mb-lg-3 mb-2" />
                        </ConsultItem>
                    </div>
                </div>
            </div>
        </div>
    )
}
