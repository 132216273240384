import React from 'react'

export default function Web({ className }) {
    return (
        <svg id="b3d35ed8-c58d-45c7-b2ab-4c4e54484768"
            data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
            width="80" height="80"
            viewBox="0 0 100 99.96" className={className}>
            <g>
                <rect x="0.04" width="99.96" height="99.96" rx="9.97" 
                className="pathOne" />

                <path d="M91.67.3H8.33A8.31,8.31,0,0,0,0,8.58V91.42A8.31,8.31,0,0,0,8.33,99.7H91.67A8.31,8.31,0,0,0,100,91.42V8.58A8.31,8.31,0,0,0,91.67.3Zm5,91.12a5,5,0,0,1-5,5H8.33a5,5,0,0,1-5-5V16.86H96.67V91.42Zm0-77.87H3.33v-5a5,5,0,0,1,5-5H91.67a5,5,0,0,1,5,5v5Z" transform="translate(0 -0.02)" style={{fill:"#002345"}} />
                <path d="M11.67,6.92H10a1.66,1.66,0,1,0,0,3.32h1.67a1.66,1.66,0,0,0,0-3.32Z" 
                transform="translate(0 -0.02)"
                    style={{fill:"#002345"}} />
                <path d="M19,7.06a1.49,1.49,0,0,0-1.27,0,2,2,0,0,0-.55.34,1.69,1.69,0,0,0,0,2.36,2,2,0,0,0,.55.34,1.56,1.56,0,0,0,1.27,0,2.1,2.1,0,0,0,.55-.34,1.69,1.69,0,0,0,0-2.36A2.1,2.1,0,0,0,19,7.06Z" transform="translate(0 -0.02)" 
                style={{fill:"#002345"}}/>
                <path d="M25.64,7a1.68,1.68,0,0,0-1.82.36,1.9,1.9,0,0,0-.35.55,1.54,1.54,0,0,0,0,1.26,1.77,1.77,0,0,0,.35.55,1.69,1.69,0,0,0,2.36,0,1.77,1.77,0,0,0,.35-.55,1.38,1.38,0,0,0,.14-.63A1.68,1.68,0,0,0,25.64,7Z" transform="translate(0 -0.02)" style={{fill:"#002345"}} />
                <path d="M90,6.92H33.33a1.66,1.66,0,1,0,0,3.32H90a1.66,1.66,0,1,0,0-3.32Z" 
                transform="translate(0 -0.02)"
                    style={{fill:"#002345"}} />
                <path d="M25.59,79.19a2.23,2.23,0,0,0,.24.19,33.33,33.33,0,0,0,48.36,0,1.88,1.88,0,0,0,.23-.16,1.28,1.28,0,0,0,.15-.25,32.79,32.79,0,0,0,0-44.64h0c0-.08-.09-.16-.14-.24a1.44,1.44,0,0,0-.25-.18,33.3,33.3,0,0,0-48.33,0,1.14,1.14,0,0,0-.25.18,1.49,1.49,0,0,0-.14.24,32.79,32.79,0,0,0,0,44.65A1,1,0,0,0,25.59,79.19ZM59.09,85A30.62,30.62,0,0,0,64.77,74.6h0a31,31,0,0,1,5.88,3.6A29.87,29.87,0,0,1,59.09,85ZM73,75.79a33.91,33.91,0,0,0-7.24-4.42,58.92,58.92,0,0,0,1.75-13.09H79.91A29.48,29.48,0,0,1,73,75.79Zm7-20.82H67.46a59.07,59.07,0,0,0-1.75-13.09A33.47,33.47,0,0,0,73,37.47,29.52,29.52,0,0,1,79.91,55ZM70.65,35.05a30.53,30.53,0,0,1-5.88,3.61,30.58,30.58,0,0,0-5.68-10.45A30,30,0,0,1,70.65,35.05Zm-19-8c4.08,1.06,7.69,5.94,10,12.86a35.6,35.6,0,0,1-10,1.88Zm0,18.06a38.9,38.9,0,0,0,10.89-2A55.18,55.18,0,0,1,64.12,55H51.67Zm0,13.18H64.12a55.09,55.09,0,0,1-1.56,11.86,38.54,38.54,0,0,0-10.89-2Zm0,13.19a35.61,35.61,0,0,1,10,1.89c-2.28,6.91-5.89,11.8-10,12.86ZM29.34,78.19a30.82,30.82,0,0,1,5.89-3.59h0A30.44,30.44,0,0,0,40.91,85,30.09,30.09,0,0,1,29.34,78.19Zm19,8c-4.08-1.07-7.69-6-10-12.86a35.61,35.61,0,0,1,10-1.89V86.22Zm0-18.06a38.47,38.47,0,0,0-10.89,2,55.74,55.74,0,0,1-1.57-11.86H48.33Zm0-13.19H35.87a55.82,55.82,0,0,1,1.57-11.86,38.84,38.84,0,0,0,10.89,2Zm0-27.93V41.78a35.26,35.26,0,0,1-10-1.89C40.63,33,44.25,28.1,48.33,27Zm-7.42,1.17a30.58,30.58,0,0,0-5.68,10.45h0a30,30,0,0,1-5.88-3.6A30,30,0,0,1,40.91,28.21ZM27,37.47a33.78,33.78,0,0,0,7.25,4.41A59.07,59.07,0,0,0,32.54,55H20.08A29.55,29.55,0,0,1,27,37.47Zm5.5,20.81a58.92,58.92,0,0,0,1.75,13.09A34.18,34.18,0,0,0,27,75.78a29.62,29.62,0,0,1-7-17.5Z"
                    transform="translate(0 -0.02)" 
                    style={{fill:"#002345"}} />
            </g>
        </svg>

    )
}
