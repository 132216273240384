import Link from 'next/link';

export default function ConsultItem({ children, link, title, definition }) {
    return (
        <div className="col-md-4 mb-3 mb-md-0 px-lg-4 px-2">
        <div className="row">
            <div className="col-12 text-center">
                <Link href={link} passHref>
                    <a>
                     {children}
                    </a>
                </Link>
            </div>
            <div className="col-12 text-center">
                <Link href={link} passHref>
                    <a className="text-5-6
                        process-item line-height-3
                        font-weight-bolder">
                            {title} 
                    </a>
                </Link>
            </div>
        </div>
        <div className="row">
            <div className="col-12 d-flex flex-column text_align_center">
                <p className="text-5 text-dark pt-lg-3 pt-1 px-1 line-height-3 "> 
                    {definition}
                </p>
                </div>
            </div>
        </div>
    )
}
