import React from 'react'

export default function KVKK({title,className}) {
    return (
        <svg id="bd95ebe4-3574-490a-af18-2405333f9bf7" 
        data-name="Layer 1" 
        className={className}
        width="80" height="80"
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100.91 100.41">
            <g>
                <rect x="0.5" y="0.5" width="99.91" height="99.91" 
                className="pathOne"/>
                <path d="M.05.05H100l-.82,66.84s-20.3-7.82-32,1.66S58.35,91.85,59.93,99H.05Z" 
                transform="translate(0.45 0.45)" 
                style={{fill: "none", stroke: "#002345", strokeMiterlimit: "10"}} />
                <rect x="17.53" y="54.3" width="61.75" height="6.64" 
                style={{fill: "#002345", stroke: "#002345", strokeMiterlimit: "10"}}  />
                <polygon points="17.53 68.29 17.53 74.93 61.29 74.93 67.62 68.29 17.53 68.29" 
                style={{fill: "#002345", stroke: "#002345", strokeMiterlimit: "10"}}  />
                <polygon points="17.16 82.27 17.16 88.41 58.48 88.41 59.04 82.27 17.16 82.27" 
                style={{fill: "#002345", stroke: "#002345", strokeMiterlimit: "10"}} />
                <polygon points="68.47 83.16 77.05 83.16 81.14 89.21 91.12 78.63 100.41 78.63 81.44 100.41 68.47 83.16" 
                style={{fill: "#002345"}} />
                <text transform="translate(17.46 28.36)" style={{fontSize: "24px", fill: "#002345"}}>{title} </text>
            </g>
        </svg>
    )
}
