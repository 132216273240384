

export default function Folder({className}) {
    return (
        <svg id="ba4327d4-e125-45fc-b0fc-274d6a050608" 
        data-name="Layer 1" 
        width="80" height="80"
        className={className}
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <path d="M92.27,7.29H41.63a4.48,4.48,0,0,1-3.39-1.61L35.65,2.73A7.22,7.22,0,0,0,30.18.15H7.73C3.47.15,0,4.1,0,9V91c0,4.86,3.47,8.81,7.73,8.81H92.27c4.26,0,7.73-3.95,7.73-8.81V16.09C100,11.24,96.53,7.29,92.27,7.29Z" transform="translate(0 0)" 
            className="pathOne" />
            <path d="M92.27,7.16H41.63a4.48,4.48,0,0,1-3.39-1.61l-2.59-3A7.23,7.23,0,0,0,30.18,0H7.73C3.47,0,0,4,0,8.83V91.17C0,96,3.47,100,7.73,100H92.27c4.26,0,7.73-4,7.73-8.83V16C100,11.12,96.53,7.16,92.27,7.16ZM97.07,18a7.15,7.15,0,0,0-4.8-1.91H90.71a1.58,1.58,0,0,0-1.47,1.67,1.59,1.59,0,0,0,1.47,1.68h1.56c2.65,0,4.8,2.46,4.8,5.48V91.17c0,3-2.15,5.48-4.8,5.48H7.73c-2.65,0-4.8-2.46-4.8-5.48V24.94c0-3,2.15-5.48,4.8-5.48H84.44a1.59,1.59,0,0,0,1.47-1.68,1.58,1.58,0,0,0-1.47-1.67H7.73A7.15,7.15,0,0,0,2.93,18V8.83c0-3,2.15-5.48,4.8-5.48H30.18A4.48,4.48,0,0,1,33.58,5l2.59,3a7.22,7.22,0,0,0,5.46,2.59H92.27c2.65,0,4.8,2.46,4.8,5.48v2Z" 
            transform="translate(0 0)" style={{fill: "#002345"}} />
            <path d="M71.1,49.13V41.78c0-8.68-9.47-15.73-21.1-15.73S28.9,33.1,28.9,41.78v7.35c-4.06.74-7.08,3.48-7.08,6.73,0,.91,1,1.65,2.21,1.65s2.2-.74,2.2-1.65c0-2,2.19-3.63,4.88-3.63H68.89c2.69,0,4.87,1.63,4.87,3.63V66.43C73.76,76.2,63.1,84.15,50,84.15s-23.77-8-23.77-17.72V62.9c0-.9-1-1.64-2.2-1.64s-2.21.74-2.21,1.64v3.53c0,11.58,12.64,21,28.18,21s28.18-9.43,28.18-21V55.86C78.18,52.61,75.16,49.87,71.1,49.13Zm-4.42-.2h-5V41.78c0-4.79-5.22-8.69-11.65-8.69S38.35,37,38.35,41.78v7.15h-5V41.78c0-6.86,7.48-12.44,16.68-12.44s16.68,5.58,16.68,12.44Z" 
            transform="translate(0 0)" style={{fill: "#002345"}} />
            <path d="M50.8,57.46a6.28,6.28,0,0,0-6.43,6.1,6.15,6.15,0,0,0,4.38,5.78V74a2.05,2.05,0,0,0,4.1,0V69.34a6.15,6.15,0,0,0,4.39-5.78A6.29,6.29,0,0,0,50.8,57.46Zm0,8.31a2.21,2.21,0,1,1,2.34-2.21A2.28,2.28,0,0,1,50.8,65.77Z" transform="translate(0 0)" 
            style={{fill: "#002345"}} />
        </svg>

    )
}
